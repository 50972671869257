import React from 'react'
import { connect } from 'react-redux'
import { Box, Grid, Typography, Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import { setCompany } from '../system/redux/reducers/globals'
import { styled } from '@mui/material/styles'
import { StaticImage } from "gatsby-plugin-image"

const StyledSelect = styled(Select)(({ theme }) => ({
    backgroundColor: theme.palette.grey.main,
    borderRadius: '6px',
    color: 'black',
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: '12px',
    height: '42px',
    margin: ' 0 0 10px',
    width: '100%',
    // black border selector
    '.MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(228, 219, 233, 0.25)',
    },
    // black border hover selector
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(228, 219, 233, 0.25)',
    },
    // blue border selector
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(228, 219, 233, 0.25)',
    },
}))



const SelectPage = ({ dispatch }) => {
    const handleSelect = (e) => {
        dispatch(setCompany(e.target.value))
    }
    return (
        <Box bgcolor="black.off" sx={{ 
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 0,
        }}>
            <Box height="100%" width="100%" sx={{ position: 'relative', zIndex: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} height='100%' width='100%'>
                    <Grid
                        alignItems={['flex-start', 'flex-start', 'flex-start', 'flex-start', 'center']}
                        container={true}
                        height='100%'
                        justifyContent='center'
                        width='100%'
                        maxWidth="350px"
                        mt={[30, 30, 20, 20, 10, 10, 0]}>
                        <Grid item xs={12} color='white.main' alignItems='center' sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box>
                                <StaticImage  src="../assets/images/barcodeWhite.png" alt="" />
                                <Typography mt={1} variant='h1'>CCS MANAGER</Typography>
                                <Typography mt={3} mb={4}>Please select the company you represent</Typography>
                                <FormControl fullWidth>
                                    <InputLabel sx={{ mt:-1, '&.MuiInputLabel-shrink':{
                                        color:'black !important'
                                    }}}>Select</InputLabel>
                                    <StyledSelect
                                        onChange={(e) => {handleSelect(e)}}
                                        fullWidth>
                                        <MenuItem value={'sab'} label="SAB">SAB</MenuItem>
                                        <MenuItem value={'sabM'} label="SAB Merchandiser">SAB Merchandiser</MenuItem>
                                        <MenuItem value={'heineken'} label="Heineken">Heineken</MenuItem>
                                        <MenuItem value={'retailer'} label='Retailer'>Retailer</MenuItem>
                                        <MenuItem value={'other'} label='Other'>Other</MenuItem>
                                    </StyledSelect>
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                zIndex: 1,
            }}>
                <StaticImage src="../assets/images/barcodeCircular.png" alt="" />
            </Box>
            <Box sx={{
                position: 'fixed',
                top: 0,
                right: 0,
                zIndex: 1,
            }}>
                <StaticImage src="../assets/svg/backgroundMark.svg" alt="" />
            </Box>
        </Box>
    )
}

export default connect()(SelectPage)

export const Head = () => <title>Select Company</title>
