export async function fetchWithTimeout(resource, options = {}) {
    const { timeout = 8000 } = options;

    const controller = new AbortController();

    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(resource, {
        ...options,
        signal: controller.signal  
    });
    clearTimeout(id);

    return response;
}

export function replaceUndefinedValues(obj, replaceWith = null) {
    const newObj = { ...obj }

    for (const key in newObj) {
        if (typeof newObj[key] === 'object') {
            newObj[key] = replaceUndefinedValues(newObj[key], replaceWith)
        } else if (newObj[key] === undefined) {
            newObj[key] = replaceWith
        }
    }

    return newObj
}