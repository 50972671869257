import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Grid, useMediaQuery } from '@mui/material'
import { getDocs, query, collection, where, doc, updateDoc, addDoc, increment, Timestamp } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { fetchWithTimeout, replaceUndefinedValues } from '../helpers/functions'
import UAParser from 'ua-parser-js'
import { useTheme } from '@mui/material/styles'
import Loading from '../components/Loading'
import CompanySelection from '../modules/CompanySelection'
import { db, functions } from '../system/firebase/index' 

const PdfRedirect = ({ company, params }) => {
    const [content, setContent] = useState(<CompanySelection />)
    const [rateLimitExceeded, setRateLimitExceeded] = useState(false) // State for rate limiting
    const [loading, setLoading] = useState(true) // State for loading
    const theme = useTheme()
    const desktop = useMediaQuery(theme.breakpoints.up('laptop'))

    useEffect(() => {
        // Function to check the rate limit
        const checkRateLimit = async () => {
            try {
                const rateLimitFunction = httpsCallable(functions, 'rateLimitFunction')
                const response = await rateLimitFunction()

                // If the rate limit is exceeded, set state and stop further execution
                if (response?.data?.error === 'Too many requests') {
                    setRateLimitExceeded(true)
                    setContent('Too many requests. Please try again later.')
                    setLoading(false) // Stop loading
                    return false
                }
                return true // Request is allowed
            } catch (error) {
                console.error('Error checking rate limit:', error)
                setRateLimitExceeded(true)
                setContent('An error occurred. Please try again later.')
                setLoading(false) // Stop loading
                return false
            }
        }

        const fetchPdfData = async () => {
            setLoading(true) // Ensure loading is set to true before fetch starts
            const fetchGeoData = async () => {
                try {
                    const ipResponse = await fetchWithTimeout('https://api.ipify.org?format=json', { timeout: 2000 })
                    const { ip } = await ipResponse.json()
                    const geoResponse = await fetchWithTimeout(`https://ipapi.co/${ip}/json/`, { timeout: 2000 })
                    const geoData = await geoResponse.json()
                    return geoData
                } catch (error) {
                    console.error('Error fetching geo data:', error)
                    return null
                }
            }

            const getSystemData = () => {
                try {
                    const userAgent = navigator.userAgent
                    const system = UAParser(userAgent)
                    return system
                } catch (error) {
                    console.error('Error getting system data:', error)
                    return null
                }
            }

            const fetchAndProcessData = async () => {
                const [geoData, systemData] = await Promise.all([fetchGeoData(), getSystemData()])
                return {
                    geo: geoData,
                    system: systemData,
                }
            }

            const data = await fetchAndProcessData()
            const refinedData = replaceUndefinedValues(data)
            const ip = data.geo ? data.geo.ip : null;
            const userAgent = data.system ? data.system.ua : null;

            try {
                const q = query(collection(db, 'listings'), where('stp', '==', params.id))
                const docSnap = await getDocs(q)
                if (docSnap.empty) {
                    setContent('Sorry, no PDF by that STP exists.')
                    setLoading(false) // Stop loading
                } else {
                    const firstDoc = docSnap.docs[0]
                    const pdfData = firstDoc.data()
                    const url = `${process.env.GATSBY_CLOUDINARY_URL}pdf/${pdfData.pdf}`
                    await addDoc(collection(db, 'visits'), {
                        listing_id: pdfData.docId,
                        pdf_stp: pdfData.stp,
                        pdf_viewed: pdfData.name,
                        timestamp: Timestamp.now(),
                        user_ip: ip,
                        user_company: company,
                        user_agent: userAgent,
                        ...refinedData,
                    })

                    const listingRef = doc(db, 'listings', pdfData.docId)
                    await updateDoc(listingRef, {
                        visits: increment(1),
                    })

                    if (desktop) {
                        window.location.href = url
                    } else {
                        setContent(
                            <Button variant="tertiary" color="primary" href={url} target="_blank" rel="noopener noreferrer">
                                Download PDF
                            </Button>
                        )
                    }
                    setLoading(false) // Stop loading after success
                }
            } catch (error) {
                console.error('Error fetching PDF data:', error)
                setContent('An error occurred while fetching PDF data.')
                setLoading(false) // Stop loading on error
            }
        }

        const handlePageLoad = async () => {
            setLoading(true) // Set loading to true when the page starts to load
            const isAllowed = await checkRateLimit() // Rate limit check
            if (isAllowed && params.id && company) {
                await fetchPdfData() // Only fetch if rate limit passed
            } else {
                setLoading(false) // Stop loading if rate limit fails
            }
        }

        handlePageLoad() // Call the rate-limiting function

    }, [params.id, company, desktop]) // Removed `check` since desktop replaces it

    if (rateLimitExceeded) return <div>{content}</div> // Display rate limit message

    return (
        <Grid alignItems='center' container={true} justifyContent='center' sx={{ display: 'flex' }} height='100vh' width='100%'>
            <Grid item>
                {loading ? <Loading color="black.main" /> : content} {/* Display loading when needed */}
            </Grid>
        </Grid>
    )
}

function mapStateToProps(state) {
    return {
        company: state.Globals.company,
    }
}

export default connect(mapStateToProps)(PdfRedirect)
